import React, { Component } from "react"
import { navigate } from "gatsby"
import { isLoggedIn } from "../../services/auth"

class PrivateRoute extends Component {
  componentDidMount() {
    if (!isLoggedIn()) {
      navigate("/signin")
      return null
    }
  }
  render() {
    const { component: Component, ...rest } = this.props
    return <Component {...rest} />
  }
}
export default PrivateRoute